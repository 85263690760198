import PropTypes from "prop-types"
import React from "react"

const HeaderAddress = ({ text }) => (
  <address style={{
    color: `#fff`,
    fontFamily: `Din Pro`,
    fontSize: `1.5rem`,
    textTransform: `uppercase`
  }}>{text}</address>
)

HeaderAddress.propTypes = {
  text: PropTypes.string,
}

HeaderAddress.defaultProps = {
  text: `Virtual Event`,
}

export default HeaderAddress