import PropTypes from "prop-types"
import React from "react"

const HeaderStrapline = ({ text }) => (
  <h3 style={{
    textTransform: `uppercase`,
    fontFamily: `Din Pro`,
    fontWeight: `bold`,
    fontSize: `1.22rem`,
    lineHeight: `3.4rem`
  }}><span style={{
    backgroundColor: `#30c4bf`,
    color: `#fff`,
    padding: `8px 15px`
    }}>Innovation Through Places, People,</span> <br /><span style={{
      backgroundColor: `#30c4bf`,
      color: `#fff`,
      padding: `8px 15px`
      }}>Processes &amp; Technology</span></h3>
)

HeaderStrapline.propTypes = {
  text: PropTypes.string,
}

HeaderStrapline.defaultProps = {
  text: ``,
}

export default HeaderStrapline