import PropTypes from "prop-types"
import React from "react"

import HeaderTitle from "../components/header-title"
import HeaderAddress from "../components/header-address"
import HeaderDate from "../components/header-date"
import HeaderSubtitle from "../components/header-subtitle"
import HeaderStrapline from "../components/header-strapline"
import PrimaryButton from "../components/primary-button"
import VenueImage from "./venue-image"
import BandsCircle from "./bands-circle"
import BandsRectangle from "./bands-rectangle"
import BannerSupportImage from "./banner-support-image"

const MainBanner = ({ text }) => (
  <div style={{
    position: `relative`,
    marginTop: `50px`
  }}>
    <div className="main-banner">
      <HeaderTitle />
      <HeaderSubtitle />
      <HeaderStrapline />
      <HeaderDate text="26th November 2020" />
      <HeaderAddress />
      <PrimaryButton to="/winners/" text="View the Winners of 2020" />&nbsp;&nbsp;&nbsp;&nbsp;
      <PrimaryButton to="/watch/" text="Watch the Ceremony" />
      <BannerSupportImage style={{maxWidth: `24rem`, margin: `1rem -.7rem`}} />
    </div>
    <VenueImage className="d-none d-lg-block" style={{
      width: `470px`,
      position: `absolute`,
      top: `0`,
      right: `0`,
      // zIndex: `-2`
    }} />
    <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 25%)`,
      width: `450px`,
      height: `450px`,
      borderRadius: `50%`,
      position: `absolute`,
      top: `20px`,
      right: `-150px`,
      zIndex: `-3`
    }} />
    <BandsCircle size="450px" style={{
      position: `absolute`,
      top: `-20px`,
      right: `-180px`,
      zIndex: `-1`
    }} />
    <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 75%)`,
      width: `100px`,
      height: `100px`,
      borderRadius: `50%`,
      position: `absolute`,
      bottom: `100px`,
      right: `380px`,
      zIndex: `-3`
    }} />
    <BandsRectangle style={{
      position: `absolute`,
      bottom: `140px`,
      right: `260px`,
      width: `120px`,
      height: `26px`,
      zIndex: `-4`
    }} />
  </div>
)

MainBanner.propTypes = {
  text: PropTypes.string,
}

MainBanner.defaultProps = {
  text: ``,
}

export default MainBanner