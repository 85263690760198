import React from "react"
import Row from "./bootstrap/row"
import SponsorBox from "./sponsor-box"
import NHSBSALogoImage from "./nhsbsa-logo-image"
import NFONLogoImage from "./nfon-logo-image"
import LakesideSoftwareLogoImage from "./lakeside-software-logo-image"
import ImgIronMountainLogo from "./img-iron-mountain-logo"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"

const HeadlineSponsors = ({gridColor}) => {

  return (
    <div style={{position: `relative`}}>
      <div style={{
        position: `absolute`,
        width: `100%`,
        height: `100%`,
        background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
        bottom: `-1rem`,
        left: `-1rem`,
        zIndex: `-1`,
      }} />
      <Row>
        <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="rgb(210, 52, 100)" fontColor="#fff" border="2px rgb(210, 52, 100) solid">Supported<br />By</SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
          <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.lakesidesoftware.com" target="_blank"><LakesideSoftwareLogoImage /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><div style={{ padding: `35px` }}><OutboundLink href="https://www.nfon.com" target="_blank"><NFONLogoImage /></OutboundLink></div></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.nhsbsa.nhs.uk" target="_blank"><NHSBSALogoImage /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
        <SponsorBox border="2px #fff solid"><OutboundLink href="https://www.ironmountain.com" target="_blank"><ImgIronMountainLogo /></OutboundLink></SponsorBox>
        </div>
        <div className={"col-6 col-md-2"}>
          <SponsorBox bgColor="transparent" border="2px #fff solid" fontColor="#fff"><Link to="/sponsors/" style={{ color: `#fff` }}>And Many More...</Link></SponsorBox>
        </div>
      </Row>
    </div>
  
  )
}

HeadlineSponsors.defaultProps = {
  gridColor: `#d23464`
  // gridColor: `rgba(251,146,1,1)`
}

console.log(HeadlineSponsors)

export default HeadlineSponsors
